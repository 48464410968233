<template>
  <div class="w-full h-screen bg-fade">
    <div class="pt-20 mb-5">
      <img
        class="object-contain h-16 w-full"
        src="../assets/mle-logo-light.png"
      />
    </div>
    <div class="text-center">
      <div class="rounded-lg bg-gray-100 inline-block py-6 px-8 text-left">
        <p v-if="errors.username" id="error" class="text-red-500">
          {{ errors.username[0] }}
        </p>
        <p v-if="showSuccess" id="success">
          We have sent a link to your email to change your password.
        </p>
        <form @submit.prevent="submit" v-if="!showSuccess">
          <div class="mb-5">
            <label class="block mb-1">Username</label>
            <input
              class="px-3 py-2 w-80 shadow focus:outline-none focus:ring focus:ring-green-400"
              type="text"
              v-model="form.username"
            />
          </div>
          <div class="mb-5">
            <button
              class="bg-green w-full rounded-md text-white p-1 shadow"
              type="submit"
            >
              Update Password
            </button>
          </div>
        </form>
      </div>
      <h2 class="text-xl text-green my-5 font-bold">REPRESENTATIVE CENTER</h2>
    </div>
  </div>
</template>

<style lang="scss">
.bg-fade {
  background: rgb(51, 51, 51);
  background: linear-gradient(
    90deg,
    rgba(51, 51, 51, 1) 0%,
    rgba(34, 34, 34, 1) 100%
  );
}
</style>
<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
      },
      errors: {},
      showSuccess: false,
    };
  },
  methods: {
    ...mapActions(["ForgotPassword"]),
    async submit() {
      try {
        this.errors = {};
        await this.ForgotPassword(this.form);
        this.showSuccess = true;
      } catch (error) {
        this.errors = error.response?.data?.errors || {};
      }
    },
  },
};
</script>
